<template>
  <fade-in>
    <div class="header-container">
      <div
        v-for="(item, index) in lang[activeLang].header_form"
        :key="item.key"
        :item="item"
        :index="index"
        :active-lang="activeLang"
      >
        <h1 v-if="item.h1">
          {{ item.h1 }}
        </h1>
        <p v-if="item.text">
          <!-- eslint-disable vue/no-v-html -->
          <span v-html="item.text" />
          <!--eslint-enable-->
        </p>
        <ul v-if="item.list">
          <!-- eslint-disable vue/no-v-html -->
          <li 
            v-for="li in item.list" 
            :key="li" 
            v-html="li" 
          />
          <!--eslint-enable-->
        </ul>
      </div>
      <p>
        <em>* </em> {{ lang[activeLang].required }}
      </p>
    </div>
  </fade-in>
</template>

<script>
import FadeIn from './FadeIn.vue'

export default {
  name: 'FormHeader',
  components: {
    FadeIn,
  },
  props: {
    activeLang: {
      type: String,
      default: 'English',
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
li {
  text-align: left;
}

a {
  text-decoration: none;
  color: #3c79b9;
}
.header-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.spacer {
  width: 2%;
}

.header-logo {
  width: 64px;
  height: 64px;
  color: black;
}

h1 {
  text-align: left;
  font-size: 32px;
}

p {
  text-align: left;
  font-size: 20px;
}

li {
  font-size: 18px;
}
</style>
