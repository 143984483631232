<template>
  <div class="navigation-container">
    <div class="logo-container">
      <a href="https://www.aclunc.org/">
        <img
          class="navigation-logo"
          src="@/assets/logo.svg"
        >
      </a>
    </div>
    <div class="lang-container">
      <div class="spacer" />
      <h3 class="sub-header">
        {{ lang[activeLang].page_title }}
      </h3>
      <form-select
        id="lang-select"
        :input="{ title: lang[activeLang].language_select.title, options: ['English', 'Spanish'], optionsObjects: lang[activeLang].language_select.options }"
        :active-lang="activeLang"
        @formUpdate="$emit('formUpdate', $event)"
      />
    </div>
  </div>
</template>

<script>
import FormSelect from './FormSelect.vue'

export default {
  name: 'Navigation',
  components: {
    FormSelect,
  },
  props: {
    activeLang: {
      type: String,
      default: "English",
    },
  },
  data() {
    return {
      backgroundImage: require("@/assets/stripe.png")
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.navigation-logo {
  width: 141px;
  height: 99px;
}

.logo-container {
  margin-left: 10px;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: stretch;
}

#lang-select {
  width: 124px;
  margin-bottom: 0em;
  outline-color: none;
  border: none;
}

.navigation-container {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
  flex-wrap: wrap;
  align-items: center;
}

.lang-container {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  flex-grow: 1;
  flex-direction: column-reverse;
  justify-content: space-between;
  margin-right: 10px;
}

.sub-header {
  display: none;
  margin-right: 1em;
  margin-left: 1em;
  color: #fff;
}

.spacer {
  flex-grow: 100;
  display: none;
}

@media screen and (min-width: 700px) {
  .logo-container {
    margin-left: 113px;
  }
  .lang-container {
    flex-direction: row;
    margin-right: 113px;
    justify-content: center;
    align-items: center;
  }
  .lang-container .form-field {
    margin-top: 0;
  }
  .sub-header{
    display: block;
  }
  .spacer {
    display: block;
  }
}



h1{
  text-align: left;
  font-size: 36px;
}

h3 {
  white-space: nowrap;
}


</style>
