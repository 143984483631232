<template>
  <div class="form-field">
    <div
      class="input-container"
      :class="{ highlighted: isFocused, hasError: input.hasError }"
    >
      <label
        class="textarea-label"
        :for="inputKey"
        :data-content="input.title"
      >{{ input.title }}<em v-if="input.required">*</em></label>
      <textarea
        :id="inputKey"
        v-model="message"
        type="text"
        :placeholder="input.title"
        class="float__textarea"
        :class="{ hasError: input.hasError }"
        :maxlength="input.charlimit"
        @input="inputUpdate(input, $event.target.value)"
        @focus="isFocused = true"
        @blur="isFocused = false"
      />
      <p class="textarea-char-count">
        {{ remainingCount }}
      </p>
    </div>
    <label
      v-if="input.hasError && input.requiredError"
      class="error-text"
    >
      {{ lang[activeLang].required_error }}</label>
    <label
      v-else-if="input.hasError && input.errorMsg"
      class="error-text"
    >{{ input.errorMsg }}</label>
  </div>
</template>

<script>
export default {
  name: 'FormTextArea',
  props: {
    formData: {
      type: Object,
      default: new Object()
    },
    activeLang: {
      type: String,
      default: 'English'
    },
    input: {
      type: Object,
      required: true
    },
    inputKey: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      remainingCount: null,
      message: String,
      isFocused: false
    }
  },
  created() {
    this.message = this.formData[this.input.index] || null
  },
  methods: {
    inputUpdate: function(input, newVal) {
      // Update the remaining characters if there's a character limit in place.
      if (input.charlimit) {
        this.remainingCount = this.remainingCount || input.charlimit
        this.remainingCount = input.charlimit - newVal.length
      }

      // Send the new value up to the parent.
      this.$emit('formUpdate', newVal)

      // If there's no error, don't perform a valid check to see if it has updated.
      if (!input.hasError) return

      // If the length increases, then it's no longer empty and we'll remove the requiredError.
      if (newVal.length > 0) {
        input.requiredError = false
      }

      // If it had a valid error we'll validate one more time.
      if (input.errorMsg && input.isValid && input.isValid(newVal)) {
        input.errorMsg = null
      }

      // If there's no validation or required error we remove the hasError property.
      if (!input.errorMsg || input.requiredError) {
        input.hasError = false
      }
    }
  }
}
</script>

<style scoped>
.input-container.input-field {
  padding-top: 0;
}

.form-field {
  width: 100%;
}

.input-container {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  /*padding-top: .5em; */
  position: relative;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px;
  outline-width: 1px;
  border-style: solid;
  border-color: #d4d6de;
  display: flex;
  flex-direction: column;
}

.highlighted {
  border-color: #9cbaf2;
}

.highlighted label {
  color: #9cbaf2;
}

.float__textarea {
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 1em;
  caret-color: #9cbaf2;
  padding: 1em;
  padding-bottom: 2em;
  font-size: 16px;
  min-height: 10rem;
  resize: vertical;
}

.float__textarea:focus ~ .textarea-char-count {
  display: block;
}

.float__textarea::placeholder {
  color: rgba(0, 0, 0, 0);
}

.textarea-label {
  margin: 1em;
  color: rgb(102, 102, 102);
  content: attr(data-content);
  filter: blur(0);
  backface-visibility: hidden;
  pointer-events: none;
  display: block;
  background: #fff;
  transition: all 0.2s ease;
  text-align: left;
}

.textarea-char-count {
  position: absolute;
  bottom: 0.5em;
  margin-top: 0;
  margin-bottom: 0;
  right: 2em;
  display: none;
  background: #fff;
  color: #9cbaf2;
  text-orientation: sideways-right;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.error-text {
  color: rgb(214, 88, 88);
  text-align: left;
  font-size: 12px;
  padding-top: 4px;
  float: left;
}

.hasError {
  border-color: rgb(214, 88, 88);
}
</style>
