<template>
  <div class="section-container">
    <div class="section-header">
      <label class="section-title">
        {{ lang[activeLang][title].title }}</label>
    </div>
    <div class="section-content">
      <div
        v-for="(row, index) in formSection.content"
        :key="index"
        class="row-container"
      >
        <slot
          v-for="(input, inputIndex) in row"
          :key="inputIndex"
        >
          <FormText
            v-if="input.type === 'text' && showInput(input)"
            :input-key="inputIndex"
            :input="input"
            :active-lang="activeLang"
            :form-data="formData"
            @formUpdate="$emit('formUpdate', inputIndex, $event)"
          />
          <FormTextArea
            v-else-if="input.type === 'textarea' && showInput(input)"
            :input="input"
            :input-key="inputIndex"
            :active-lang="activeLang"
            :form-data="formData"
            @formUpdate="$emit('formUpdate', inputIndex, $event)"
          />
          <FormSelect
            v-else-if="input.type === 'select' && showInput(input)"
            :input="input"
            :input-key="inputIndex"
            :active-lang="activeLang"
            :form-data="formData"
            @formUpdate="$emit('formUpdate', inputIndex, $event)"
          />
          <FormDatePicker
            v-else-if="input.type === 'datepicker' && showInput(input)"
            :input="input"
            :input-key="inputIndex"
            :active-lang="activeLang"
            :form-data="formData"
            @formUpdate="$emit('formUpdate', inputIndex, $event)"
          />
          <FormButton
            v-else-if="input.type === 'button' && showInput(input)"
            :input="input"
            :input-key="inputIndex"
            :active-lang="activeLang"
            :form-data="formData"
            @formUpdate="$emit('formUpdate', inputIndex, $event)"
          />
          <FormNotice
            v-else-if="input.type === 'notice' && showInput(input)"
            :input="input"
            :input-key="inputIndex"
            :active-lang="activeLang"
            :form-data="formData"
            @formUpdate="$emit('formUpdate', inputIndex, $event)"
          />
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import FormText from './FormText.vue'
import FormSelect from './FormSelect.vue'
import FormDatePicker from './FormDatePicker.vue'
import FormTextArea from './FormTextArea.vue'
import FormButton from './FormButton.vue'
import FormNotice from './FormNotice.vue'

export default {
  name: 'FormSection',
  components: {
    FormSelect,
    FormText,
    FormTextArea,
    FormButton,
    FormNotice,
    FormDatePicker
  },
  props: {
    formData: {
      type: Object,
      default: new Object()
    },
    activeLang: {
      type: String,
      default: 'English'
    },
    title: {
      type: String,
      required: true
    },
    formSection: {
      type: Object,
      required: true
    }
  },
  methods: {
    showInput(input) {
      if (typeof input.shouldShow === 'undefined') {
        return true
      }
      return input.shouldShow()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.row-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 0;
}

.section-container {
  padding-top: 1em;
}

.section-content {
  display: block;
}
.section-header {
  background-color: #3c79b9;
  display: flex;
  padding: 15px 10px;
  border-radius: 5px;
}

.section-title {
  color: #fff;
  margin: 0;
}
</style>
