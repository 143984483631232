<template>
  <div class="footer-container">
    <div
      class="logo-container"
    >
      <a
        href="https://www.aclunc.org/"
        target="_blank"
      >
        <img
          class="navigation-logo"
          src="@/assets/logo.svg"
        >
      </a>
    </div>
    <div
      class="footer-nav-container"
    >
      <div
        v-for="(link, index) in links"
        :key="index"
        class="link-col"
      >
        <a
          :href="link.url"
          target="_blank"
        >
          <h3>{{ link.title }}</h3>
          <a
            v-for="(sublink, subIndex) in link.sublinks"
            :key="subIndex"
            :href="sublink.url"
          >
            <p>{{ sublink.title }}</p>
          </a>
        </a>
      </div>
    </div>
    <div class="block-copyrightblock">
      Media Contact: (415) 621-2493 (press@aclunc.org) | <a href="https://www.aclunc.org/about/user-agreement"> User Agreement <a /> | <a href="https://www.aclunc.org/online-privacy-policy"> Privacy Statement <a />
      </a></a>
    </div> 
  </div>
</template>





<script>
export default {
  name: 'Footer',
  props: {
    activeLang: {
      type: String,
      default: 'English'
    }
  },
  data() {
    return {
      links: [
        {
          title: 'WHO WE ARE',
          url: 'https://www.aclunc.org/who-we-are',
          sublinks: [
            { title: 'History', url: 'https://www.aclunc.org/history' },
            { title: 'Staff', url: 'https://www.aclunc.org/staff' },
            {
              title: 'Boards of Directors',
              url: 'https://www.aclunc.org/board-of-directors'
            },
            { title: 'Work with Us', url: 'https://www.aclunc.org/about/jobs' },
            {
              title: 'Financials & Reports',
              url: 'https://www.aclunc.org/financial-info-reports'
            }
          ]
        },
        {
          title: 'WHAT WE DO',
          url: 'https://www.aclunc.org/our-work',
          sublinks: [
            { title: 'Our Work', url: 'https://www.aclunc.org/our-work' },
            { title: 'Know Your Rights', url: 'https://www.aclunc.org/our-work/know-your-rights' },
            {
              title: 'Legislation',
              url: 'https://www.aclunc.org/our-work/legislation'
            },
            { title: 'Legal Docket', url: 'https://www.aclunc.org/our-work/legal-docket' },
            {
              title: 'Need Legal Help?',
              url: 'https://www.aclunc.org/our-work/get-help'
            }
          ]
        },
        {
          title: 'NEWS',
          url: 'https://www.aclunc.org/news',
          sublinks: [
            { title: 'Blog', url: 'https://www.aclunc.org/blog' },
            { title: 'Newsletters', url: 'https://www.aclunc.org/news/newsletters' },
            {
              title: 'Press Releases',
              url: 'https://www.aclunc.org/news'
            },
            { title: 'Publications', url: 'https://www.aclunc.org/publications-0' },
          ]
        },
        {
          title: 'TAKE ACTION',
          url: 'https://www.aclunc.org/take-action',
          sublinks: [
            { title: 'Actions', url: 'https://www.aclunc.org/take-action/get-involved' },
            { title: 'Become a Member', url: 'https://www.aclunc.org/aclu-membership' },
            {
              title: 'Chapters',
              url: 'https://www.aclunc.org/about/chapters'
            },
          ]
        },
        {
          title: 'DONATE',
          url: 'https://www.aclunc.org/donating-aclu-general-information',
          sublinks: [
            { title: 'General Information', url: 'https://www.aclunc.org/donating-aclu-general-information' },
            { title: 'Estate Plan Gifts', url: 'https://www.aclunc.org/donate/planned-giving' },
            {
              title: 'Gifts of Stock',
              url: 'https://www.aclunc.org/gifts-stock'
            },
            { title: 'Tribute Gifts', url: 'https://www.aclunc.org/tribute-gift-aclu-foundation-northern-california' },
            {
              title: 'Membership',
              url: 'https://www.aclunc.org/aclu-membership'
            },
            {
              title: 'FAQ',
              url: 'https://www.aclunc.org/donate/frequently-asked-questions'
            }
          ]
        }
      ]
    }
  }
}
</script>

<style scoped>
.footer-container {
  color: #fff;
  background-color: #463f3d;
  padding: 0px 0 0px 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.logo-container {
  width: 202px;
  display: none;
}

.navigation-logo {
  width: 141px;
  height: 99px;
  padding: 0 30px;
}

.footer-nav-container {
  display: none;
  padding: 0 15px 15px 0;
  width: 1000px;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media screen and (min-width: 700px) {
  .footer-container {
    padding: 50px 0 60px 0;
  }
  .logo-container {
    display: flex;
  }
  .footer-nav-container {
    display: flex;
  }
}

.block-copyrightblock {
  width: 100%;
  color: #939393;
  margin: 30px 10px 30px 0;
}

.block-copyrightblock a {
  color: #939393;
 
}

.link-col {
  display: flex;
}

a {
  text-decoration: none;
  color: #fff;
}

h3 {
  text-align: left;
  margin-top: 0;
  margin-bottom: 15px;
}

p {
  text-align: left;
  margin-top: 0px;
  margin-bottom: 5px;
}

.sublink {
  font-size: 0.72rem;
  margin-right: 11px;
}

</style>