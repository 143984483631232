<template>
  <div class="container-main">
    <div
      v-if="debugging"
      class="debugging-container"
    >
      <pre v-if="JSONData">
      <code>
        {{ JSONData }}
      </code>
      </pre>
      <h1 style="color: rgb(214, 88, 88)">
        DEBUGGING MODE ENABLED.
      </h1>
      <div
        class="debug-button"
        @click="clearFormData"
      >
        <label class="submit-button-text"> CLEAR LOCAL DATA </label>
      </div>
      <div
        class="debug-button"
        @click="submitForm"
      >
        <label class="submit-button-text"> TEST SUBMIT </label>
      </div>
    </div>
    <FormCompleteHeader
      v-if="submitted === true"
      id="FormCompleteHeader"
      :active-lang="activeLang"
    />
    <FormHomeHeader
      v-else-if="!isLocationValid"
      :active-lang="activeLang"
    />
    <FormFileHeader
      v-else-if="isLocationValid"
      id="FormFileHeader"
      :active-lang="activeLang"
    />
    <label
      v-if="formError === true"
      id="error-notice"
    >
      {{ lang[activeLang].review_error }}</label>
    <Loading v-if="loading" />
    <div v-if="submitted != true && !loading">
      <slot v-for="(formSection, index) in formStructure">
        <form-section
          v-if="
            typeof formSection.shouldShow === 'undefined' ||
              (typeof formSection.shouldShow != 'undefined' &&
                formSection.shouldShow() === true)
          "
          :id="index"
          :key="index"
          :title="index"
          :form-section="formSection"
          :active-lang="activeLang"
          :form-data="formData"
          @formUpdate="formFieldChanged"
        />
      </slot>
      <div
        v-if="isLocationValid"
        class="submit-button"
        @click="submitForm"
      >
        <label class="submit-button-text">
          {{ lang[activeLang].submit }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import FormSection from './FormSection.vue'
import FormHomeHeader from './FormHomeHeader.vue'
import FormFileHeader from './FormFileHeader.vue'
import FormCompleteHeader from './FormCompleteHeader.vue'
import Loading from './Loading.vue'
import states from '../states.js'
import formRequest from "../api.js"

const SanDiegoCounties = [
  'San Diego',
  'Imperial',
]

const SoCalCounties = [
  'San Luis Obispo',
  'Kern',
  'Santa Barbara',
  'Ventura',
  'Los Angeles',
  'San Bernardino',
  'Orange',
  'Riverside',
]

const NorCalCounties = [
  'Alameda',
  'Alpine',
  'Amador',
  'Butte',
  'Calaveras',
  'Colusa',
  'Contra Costa',
  'Del Norte',
  'El Dorado',
  'Fresno',
  'Glenn',
  'Humboldt',
  'Inyo',
  'Kings',
  'Lake',
  'Lassen',
  'Madera',
  'Marin',
  'Mariposa',
  'Mendocino',
  'Merced',
  'Modoc',
  'Mono',
  'Monterey',
  'Napa',
  'Nevada',
  'Placer',
  'Plumas',
  'Sacramento',
  'San Benito',
  'San Francisco',
  'San Joaquin',
  'San Mateo',
  'Santa Clara',
  'Santa Cruz',
  'Shasta',
  'Sierra',
  'Siskiyou',
  'Solano',
  'Sonoma',
  'Stanislaus',
  'Sutter',
  'Tehama',
  'Trinity',
  'Tulare',
  'Tuolumne',
  'Yolo',
  'Yuba',
]

const NonCalCounties = [
  "Outside of California",
]

const CalCounties = NorCalCounties.concat(SanDiegoCounties).concat(SoCalCounties).concat(NonCalCounties).sort()

function isValidEmail(email) {
  // Regular expression taken from: https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i
  if (!re.test(email)) {
    return false
  }
  return true
}

function isValidPhoneNumber(number) {
  const re = /^(\+\d+)?\s*\(?\d+\)?[\s.-]?\d*[\s.-]?\d*$/
  if (!re.test(number)) {
    return false
  }
  return true
}

function isValidZipCode(zipCode) {
  const re = /^\d{5}(?:[-\s]\d{4})?$/
  if (!re.test(zipCode)) {
    return false
  }
  return true
}

export default {
  name: 'InquiryForm',
  components: {
    FormSection,
    FormHomeHeader,
    FormCompleteHeader,
    FormFileHeader,
    Loading
  },
  props: {
    activeLang: {
      type: String,
      default: localStorage.activeLang || 'English',
    },
  },
  data() {
    return {
      formData: JSON.parse(localStorage.formData || '{}'),
      erroredFields: [],
      JSONData: null,
      submitted: false,
      formError: false,
      debugging: false,
      loading: true,
      formStructure: {},
    }
  },
  computed: {
    isLocationValid() {
      return NorCalCounties.includes(this.getFormData('incident_in_state'))
    }
  },
  watch:{
    activeLang() {
      location.reload();
    }
  },
  async beforeMount() {
    this.loading = true
    try {
      await formRequest.requestFormData()
      this.loading = false
    } catch (error) {
      // console.log(error)
    }
  },
  mounted() {
    // Build form inputs here.

    /* 
      INCIDENT LOCATION
    */
    this.addSection('location_select')

    this.addToRow('location_select', 0, 'location_notice', {
      lines: this.lang[this.activeLang].location_select.notice,
      noticeObj: 'location_select',
      type: 'notice',
    })

    this.addToRow('location_select', 1, 'incident_in_state', {
      title: this.lang[this.activeLang].location_select.county_select.title,
      type: 'select',
      options: CalCounties,
      required: true,
      fullwidth: true,
    })

    this.addToRow('location_select', 2, 'visit_affiliates', {
      title: this.lang[this.activeLang].location_select.county_select.error.notCal.title,
      type: 'button',
      url: this.lang[this.activeLang].location_select.county_select.error.notCal.url,
      shouldShow: () => {
        return NonCalCounties.includes(this.getFormData('incident_in_state'))
      },
    })

    this.addToRow('location_select', 3, 'visit_socal', {
      title: this.lang[this.activeLang].location_select.county_select.error.south.title,
      type: 'button',
      url: this.lang[this.activeLang].location_select.county_select.error.south.url,
      shouldShow: () => {
        return SoCalCounties.includes(this.getFormData('incident_in_state'))
      },
    }) 

    this.addToRow('location_select', 3, 'visit_san_diego', {
      title: this.lang[this.activeLang].location_select.county_select.error.sanDiego.title,
      type: 'button',
      url: this.lang[this.activeLang].location_select.county_select.error.sanDiego.url,
      shouldShow: () => {
        return SanDiegoCounties.includes(this.getFormData('incident_in_state'))
      },
    }) 

    /* 
      CONTACT INFORMATION
    */
    this.addSection('contact_info', {
      shouldShow: () => {
        return NorCalCounties.includes(this.getFormData('incident_in_state'))
      },
    })

    this.addToRow('contact_info', 0, 'affected_person', {
      title: this.lang[this.activeLang].contact_info.self_select.title,
      type: 'select',
      options: ['Myself', 'Someone else'],
      optionsObjects: this.lang[this.activeLang].contact_info.self_select.options,
      required: true,
      fullwidth: true,
    })

    this.addToRow('contact_info', 1, 'filer_relationship', {
      title: this.lang[this.activeLang].contact_info.relationship_select.title,
      type: 'select',
      options: ['Relative', 'Spouse', 'Friend'],
      optionsObjects: this.lang[this.activeLang].contact_info.relationship_select.options,
      required: true,
      fullwidth: true,
      shouldShow: () => {
        return this.getFormData('affected_person') === 'Someone else'
      },
    })

    this.addToRow('contact_info', 2, 'affected_notice', {
      lines: this.lang[this.activeLang].contact_info.affected_notice,
      type: 'notice',
      shouldShow: () => {
        return this.getFormData('affected_person') === 'Someone else'
      },
    })

    this.addToRow('contact_info', 3, 'affected_firstname', {
      title: this.lang[this.activeLang].contact_info.a_first_name,
      type: 'text',
      maxlength: 50,
      shouldShow: () => {
        return this.getFormData('affected_person')
      },
      required: true,
    })

    this.addToRow('contact_info', 3, 'affected_lastname', {
      title: this.lang[this.activeLang].contact_info.a_last_name,
      type: 'text',
      maxlength: 50,
      shouldShow: () => {
        return this.getFormData('affected_person')
      },
      required: true,
    })

    this.addToRow('contact_info', 4, 'affected_pronoun', {
      title: this.lang[this.activeLang].contact_info.pronoun_select.title,
      type: 'select',
      options: formRequest.apiData.get_pronouns.options,
      optionsObjects: this.lang[this.activeLang].contact_info.pronoun_select.options,
      fullwidth: true,
      shouldShow: () => {
        return this.getFormData('affected_person')
      },
    })

    this.addToRow('contact_info', 4, 'affected_pronoun_typed', {
      title: 'Please Input a Gender Pronoun',
      type: 'text',
      maxlength: 30,
      fullwidth: true,
      shouldShow: () => {
        return this.getFormData('affected_pronoun') === 'Other'
      },
    })

    this.addToRow('contact_info', 4, 'affected_ethnicity', {
      title: this.lang[this.activeLang].contact_info.ethnic_select.title,
      type: 'select',
      options: formRequest.apiData.get_ethnicities.options,
      optionsObjects: this.lang[this.activeLang].contact_info.ethnic_select.options,
      fullwidth: true,
      shouldShow: () => {
        return this.getFormData('affected_person')
      },
    })

    this.addToRow('contact_info', 5, 'affected_address', {
      title: this.lang[this.activeLang].contact_info.address,
      type: 'text',
      maxlength: 80,
      shouldShow: () => {
        return this.getFormData('affected_person')
      },
    })

    this.addToRow('contact_info', 5, 'affected_address2', {
      title: this.lang[this.activeLang].contact_info.address_2,
      type: 'text',
      maxlength: 80,
      shouldShow: () => {
        return this.getFormData('affected_person')
      },
    })

    this.addToRow('contact_info', 6, 'affected_city', {
      title: this.lang[this.activeLang].contact_info.city,
      type: 'text',
      maxlength: 20,
      shouldShow: () => {
        return this.getFormData('affected_person')
      },
    })

    this.addToRow('contact_info', 6, 'affected_state', {
      title: this.lang[this.activeLang].contact_info.state,
      type: 'select',
      options: states,
      shouldShow: () => {
        return this.getFormData('affected_person')
      },
    })

    this.addToRow('contact_info', 6, 'affected_zip', {
      title: this.lang[this.activeLang].contact_info.zip,
      error: this.lang[this.activeLang].contact_info.error.zip,
      type: 'text',
      maxlength: 5,
      isValid(zipCode) {
        if (!isValidZipCode(zipCode)) {
          return this.error
        }
      },
      shouldShow: () => {
        return this.getFormData('affected_person')
      },
    })

    this.addToRow('contact_info', 6, 'affected_county', {
      title: this.lang[this.activeLang].contact_info.county,
      type: 'select',
      options: CalCounties,
      shouldShow: () => {
        return this.getFormData('affected_person')
      },
      required: true,
    })

    this.addToRow('contact_info', 7, 'affected_phone', {
      title: this.lang[this.activeLang].contact_info.phone,
      error: this.lang[this.activeLang].contact_info.error.phone,
      type: 'text',
      maxlength: 20,
      isValid(phoneNumber) {
        if (!isValidPhoneNumber(phoneNumber)) {
          return this.error;
        }
      },
      shouldShow: () => {
        return this.getFormData('affected_person')
      },
    })

    this.addToRow('contact_info', 7, 'affected_email', {
      title: this.lang[this.activeLang].contact_info.email,
      error: this.lang[this.activeLang].contact_info.error.email,
      type: 'text',
      maxlength: 90,
      isValid(email) {
        if (!isValidEmail(email)) {
          return this.error;
        }
      },
      shouldShow: () => {
        return this.getFormData('affected_person')
      },
      required: true,
    })

    this.addToRow('contact_info', 7, 'affected_prison_name', {
      title: this.lang[this.activeLang].contact_info.prison_select.title,
      type: 'select',
      options: formRequest.apiData.get_prisons.options,
      shouldShow: () => {
        return this.getFormData('affected_person')
      },
    })

    this.addToRow('contact_info', 7, 'affected_prison_number', {
      title: this.lang[this.activeLang].contact_info.p_number,
      type: 'text',
      maxlength: 100,
      shouldShow: () => {
        return this.getFormData('affected_person')
      },
    })

    /* 
      FILER CONTACT INFORMATION
    */

    this.addToRow('contact_info', 8, 'filer_notice', {
      lines: this.lang[this.activeLang].contact_info.filer_notice,
      type: 'notice',
      shouldShow: () => {
        return this.getFormData('affected_person') === 'Someone else'
      },
    })

    this.addToRow('contact_info', 9, 'filer_firstname', {
      title: this.lang[this.activeLang].contact_info.first_name,
      type: 'text',
      maxlength: 50,
      shouldShow: () => {
        return this.getFormData('affected_person') === 'Someone else'
      },
      required: true,
    })

    this.addToRow('contact_info', 9, 'filer_lastname', {
      title: this.lang[this.activeLang].contact_info.last_name,
      type: 'text',
      maxlength: 50,
      shouldShow: () => {
        return this.getFormData('affected_person') === 'Someone else'
      },
      required: true,
    })

    this.addToRow('contact_info', 10, 'filer_pronouns', {
      title: this.lang[this.activeLang].contact_info.pronoun_select.title,
      type: 'select',
      options: formRequest.apiData.get_pronouns.options,
      optionsObjects: this.lang[this.activeLang].contact_info.pronoun_select.options,
      fullwidth: true,
      shouldShow: () => {
        return this.getFormData('affected_person') === 'Someone else'
      },
    })

    this.addToRow('contact_info', 10, 'filer_pronouns_typed', {
      title: 'Please Input a Gender Pronoun',
      type: 'text',
      maxlength: 30,
      fullwidth: true,
      shouldShow: () => {
        return (
          this.getFormData('affected_person') === 'Someone else' &&
          this.getFormData('filer_pronouns') === 'Other'
        )
      },
    })

    this.addToRow('contact_info', 10, 'filer_ethnicity', {
      title: this.lang[this.activeLang].contact_info.ethnic_select.title,
      type: 'select',
      options: formRequest.apiData.get_ethnicities.options,
      optionsObjects: this.lang[this.activeLang].contact_info.ethnic_select.options,
      fullwidth: true,
      shouldShow: () => {
        return this.getFormData('affected_person') === 'Someone else'
      },
    })

    this.addToRow('contact_info', 10, 'filer_address', {
      title: this.lang[this.activeLang].contact_info.address,
      type: 'text',
      maxlength: 80,
      shouldShow: () => {
        return this.getFormData('affected_person') === 'Someone else'
      },
    })

    this.addToRow('contact_info', 10, 'filer_address2', {
      title: this.lang[this.activeLang].contact_info.address_2,
      type: 'text',
      maxlength: 80,
      shouldShow: () => {
        return this.getFormData('affected_person') === 'Someone else'
      },
    })

    this.addToRow('contact_info', 10, 'filer_city', {
      title: this.lang[this.activeLang].contact_info.city,
      type: 'text',
      maxlength: 20,
      shouldShow: () => {
        return this.getFormData('affected_person') === 'Someone else'
      },
    })

    this.addToRow('contact_info', 10, 'filer_state', {
      title: this.lang[this.activeLang].contact_info.state,
      type: 'select',
      options: states,
      shouldShow: () => {
        return this.getFormData('affected_person') === 'Someone else'
      },
    })

    this.addToRow('contact_info', 11, 'filer_zip', {
      title: this.lang[this.activeLang].contact_info.zip,
      type: 'text',
      maxlength: 5,
      isValid(zipCode) {
        if (!isValidZipCode(zipCode)) {
          return this.lang[this.activeLang].contact_info.error.zip
        }
      },
      shouldShow: () => {
        return this.getFormData('affected_person') === 'Someone else'
      },
    })

    this.addToRow('contact_info', 11, 'filer_county', {
      title: this.lang[this.activeLang].contact_info.county,
      type: 'select',
      options: CalCounties,
      shouldShow: () => {
        return this.getFormData('affected_person') === 'Someone else'
      },
      required: true,
    })

    this.addToRow('contact_info', 12, 'filer_phone', {
      title: this.lang[this.activeLang].contact_info.phone,
      error: this.lang[this.activeLang].contact_info.error.phone,
      type: 'text',
      maxlength: 20,
      isValid(phoneNumber) {
        if (!isValidPhoneNumber(phoneNumber)) {
          return this.error;
        }
      },
      shouldShow: () => {
        return this.getFormData('affected_person') === 'Someone else'
      },
    })

    this.addToRow('contact_info', 12, 'filer_email', {
      title: this.lang[this.activeLang].contact_info.email,
      error: this.lang[this.activeLang].contact_info.error.email,
      type: 'text',
      maxlength: 90,
      isValid(email) {
        if (!isValidEmail(email)) {
          return this.error;
        }
      },
      shouldShow: () => {
        return this.getFormData('affected_person') === 'Someone else'
      },
      required: true,
    })

    this.addToRow('contact_info', 12, 'filer_prison_name', {
      title: this.lang[this.activeLang].contact_info.prison_select.title,
      type: 'select',
      options: formRequest.apiData.get_prisons.options,
      shouldShow: () => {
        return this.getFormData('affected_person') === 'Someone else'
      },
    })

    this.addToRow('contact_info', 12, 'filer_prison_number', {
      title: this.lang[this.activeLang].contact_info.p_number,
      type: 'text',
      maxlength: 100,
      shouldShow: () => {
        return this.getFormData('affected_person') === 'Someone else'
      },
    })

    /*
      ACLU CALL NOTICED
    */

    this.addToRow('contact_info', 13, 'aclu_call_id', {
      fullwidth: true,
      title: this.lang[this.activeLang].contact_info.aclu_call,
      type: 'select',
      options: ['Yes', 'No'],
      optionsObjects: this.lang[this.activeLang]
    })

    /* 
      COMPLAINT AGAINST
    */
    this.addSection('complaint_against', {
      shouldShow: () => {
        return NorCalCounties.includes(this.getFormData('incident_in_state'))
      },
    })

    this.addToRow('complaint_against', 0, 'against_firstname', {
      title: this.lang[this.activeLang].contact_info.first_name,
      type: 'text',
      maxlength: 50,
    })

    this.addToRow('complaint_against', 0, 'against_lastname', {
      title: this.lang[this.activeLang].contact_info.last_name,
      type: 'text',
      maxlength: 50,
    })

    this.addToRow('complaint_against', 1, 'against_address', {
      title: this.lang[this.activeLang].contact_info.address,
      type: 'text',
      maxlength: 80,
    })

    this.addToRow('complaint_against', 1, 'against_address2', {
      title: this.lang[this.activeLang].contact_info.address_2,
      type: 'text',
      maxlength: 80,
    })

    this.addToRow('complaint_against', 2, 'against_city', {
      title: this.lang[this.activeLang].contact_info.city,
      type: 'text',
      maxlength: 20,
    })

    this.addToRow('complaint_against', 2, 'against_state', {
      title: this.lang[this.activeLang].contact_info.state,
      type: 'select',
      options: states,
    })

    this.addToRow('complaint_against', 2, 'against_zip', {
      title: this.lang[this.activeLang].contact_info.zip,
      error: this.lang[this.activeLang].contact_info.error.zip,
      type: 'text',
      maxlength: 5,
      isValid(zipCode) {
        if (!isValidZipCode(zipCode)) {
          return this.error;
        }
      },
    })

    this.addToRow('complaint_against', 3, 'against_phone', {
      title: this.lang[this.activeLang].contact_info.phone,
      error: this.lang[this.activeLang].contact_info.error.phone,
      type: 'text',
      maxlength: 20,
      isValid(phoneNumber) {
        if (!isValidPhoneNumber(phoneNumber)) {
          return this.error;
        }
      },
    })

    this.addToRow('complaint_against', 3, 'against_email', {
      title: this.lang[this.activeLang].contact_info.email,
      error: this.lang[this.activeLang].contact_info.error.email,
      type: 'text',
      maxlength: 90,
      isValid(email) {
        if (!isValidEmail(email)) {
          return this.error;
        }
      },
    })

    this.addToRow('complaint_against', 4, 'against_agency', {
      title: this.lang[this.activeLang].complaint_against.agency,
      type: 'text',
      maxlength: 50,
    })

    this.addToRow('complaint_against', 4, 'against_affected_permission', {
      title: this.lang[this.activeLang].complaint_against.agency_select.title,
      type: 'select',
      options: ['Yes', 'No'],
      optionsObjects: this.lang[this.activeLang]
    })

    /* 
      ATTORNEY INFORMATION
    */
    this.addSection('attorney', {
      shouldShow: () => {
        return NorCalCounties.includes(this.getFormData('incident_in_state'))
      },
    })

    this.addToRow('attorney', 0, 'has_attorney', {
      title: this.lang[this.activeLang].attorney.attorney_select.title,
      type: 'select',
      options: ['Yes', 'No'],
      optionsObjects: this.lang[this.activeLang],
      fullwidth: true,
      required: true
    })

    this.addToRow('attorney', 1, 'attorney_firstname', {
      title: this.lang[this.activeLang].contact_info.first_name,
      type: 'text',
      maxlength: 50,
      shouldShow: () => {
        return this.getFormData('has_attorney') == 'Yes'
      },
      required: true,
    })

    this.addToRow('attorney', 1, 'attorney_lastname', {
      title: this.lang[this.activeLang].contact_info.last_name,
      type: 'text',
      maxlength: 50,
      shouldShow: () => {
        return this.getFormData('has_attorney') == 'Yes'
      },
      required: true,
    })

    this.addToRow('attorney', 2, 'attorney_phone', {
      title: this.lang[this.activeLang].contact_info.phone,
      error: this.lang[this.activeLang].contact_info.error.phone,
      type: 'text',
      maxlength: 20,
      isValid(phoneNumber) {
        if (!isValidPhoneNumber(phoneNumber)) {
          return this.error;
        }
      },
      shouldShow: () => {
        return this.getFormData('has_attorney') == 'Yes'
      },
    })

    this.addToRow('attorney', 2, 'attorney_email', {
      title: this.lang[this.activeLang].contact_info.email,
      error: this.lang[this.activeLang].contact_info.error.email,
      type: 'text',
      maxlength: 90,
      isValid(email) {
        if (!isValidEmail(email)) {
          return this.error
        }
      },
      shouldShow: () => {
        return this.getFormData('has_attorney') == 'Yes'
      },
    })

    /* 
      HAVE YOU REPORTED?
    */
    this.addSection('reported', {
      shouldShow: () => {
        return NorCalCounties.includes(this.getFormData('incident_in_state'))
      },
    })

    this.addToRow('reported', 0, 'has_reported', {
      title: this.lang[this.activeLang].reported.reported_select.title,
      type: 'select',
      options: ['Yes', 'No'],
      optionsObjects: this.lang[this.activeLang],
      required: true,
      fullwidth: true
    })

    this.addToRow('reported', 1, 'reported_agency_names', {
      title: this.lang[this.activeLang].reported.reported_select.details,
      type: 'textarea',
      charlimit: 3000,
      shouldShow: () => {
        return this.getFormData('has_reported') == 'Yes'
      },
      required: true,
    })

    /* 
      INCIDENT INFORMATION
    */
    this.addSection('incident', {
      shouldShow: () => {
        return NorCalCounties.includes(this.getFormData('incident_in_state'))
      },
    })

    this.addToRow('incident', 0, 'incident_date', {
      title: this.lang[this.activeLang].incident.date_select,
      type: 'datepicker',
      required: true,
    })

    this.addToRow('incident', 1, 'incident_reason', {
      title: this.lang[this.activeLang].incident.what_happened,
      type: 'textarea',
      charlimit: 3000,
      required: true,
    })

    this.addToRow('incident', 2, 'incident_resolution', {
      title: this.lang[this.activeLang].incident.to_do,
      type: 'textarea',
      charlimit: 3000,
      required: true,
    })

    /* 
      DISCLAIMER AND NOTICE.
    */
    this.addSection('disclaimer', {
      shouldShow: () => {
        return NorCalCounties.includes(this.getFormData('incident_in_state'))
      },
    })

    this.addToRow('disclaimer', 0, 'disclaimer_notice', {
      lines: this.lang[this.activeLang].disclaimer.notice,
      type: 'notice',
    })

    /* 
      AGREEMENT
    */
    this.addSection('agreement', {
      shouldShow: () => {
        return NorCalCounties.includes(this.getFormData('incident_in_state'))
      },
    })

    this.addToRow('agreement', 0, 'agreement_notice', {
      lines: this.lang[this.activeLang].agreement.notice,
      type: 'notice',
    })
  },
  methods: {
    formFieldChanged(fieldID, val) {
      // Storing the values into local storage so data is maintained across page navigations.
      this.formData[fieldID] = val
      localStorage.formData = JSON.stringify(this.formData)
    },
    clearFormData() {
      delete localStorage.formData
    },
    async submitForm() {
      // Make sure the form is valid before properly submitting
      const isValid = this.isFormValid()
      this.JSONData = JSON.stringify(this.formData, null, 2)
      if (!isValid) {
        this.formError = true
        // Loop over all erroredFields and find one that has an error.
        for (let i = 0; i < this.erroredFields.length; i++) {
          let input = this.erroredFields[i]
          if (!input.hasError) {
            // Remove it from the array since it's no longer relevent
            this.erroredFields.splice(i, 1)
            continue
          }

          // Scroll to errored element for user to see
          let elmnt = document.getElementById(input.index)
          elmnt.scrollIntoView({ block: 'center' })
          break
        }
      } else {
        const status = await formRequest.submitForm(this.JSONData)
        if (status === 200) {
          this.submitted = true
          this.formError = false      
        }
        // Do not delete stored data if debugging.
         if (!this.debugging) delete localStorage.formData
      }
    },
    addSection(title, sectionData) {
      this.formStructure[title] = sectionData || {}
    },
    addToRow(section, rowID, index, content) {
      content.section = section
      content.index = index
      // Add a content to field to the section if it does not exist already.
      this.formStructure[section].content =
        this.formStructure[section].content || []
      // Create a new row to the section content if it does not exist already.
      this.formStructure[section].content[rowID] =
        this.formStructure[section].content[rowID] || {}
      // Finally add the content to the section, content, row.
      this.formStructure[section].content[rowID][index] = content
    },
    getFormData(index) {
      // Alternative to indexing form data.
      return this.formData[index]
    },
    getInputError(index, input) {
      let showingSection = this.formStructure[input.section].shouldShow

      // If the section is not visible, it is not required to have a value.
      if (showingSection && !showingSection()) return false

      // If the input is not visible, it is not required to have a value.
      let showingInput = input.shouldShow
      if (showingInput && !showingInput()) {
        return false
      }

      // If the input is visible and is not required and is empty we're not gonna perform any valid checks.
      if (!input.required && !this.formData[index]) return false

      // If the input is required and is not inside the formData, then they skipped this input.
      if (!this.formData[index] || this.formData[index] === '') {
        input.hasError = true
        input.requiredError = true
        return true
      }

      // Finally perform valid checks such as email, zip or phone number.
      if (input.isValid) {
        let errorMsg = input.isValid(this.formData[input.index])
        if (errorMsg) {
          input.hasError = true
          input.errorMsg = errorMsg
          return true
        }
      }
    },
    isFormValid() {
      // TODO: Refactor formStructure with a better data structure.
      let isValid = true
      this.errorFields = []
      // eslint-disable-next-line no-unused-vars
      for (let [title, section] of Object.entries(this.formStructure)) {
        for (let inputs of section.content) {
          for (let [inputIndex, input] of Object.entries(inputs)) {
            // Reset any previous errors when performing the form valid check.
            input.hasError = false
            if (this.getInputError(inputIndex, input)) {
              isValid = false
              this.erroredFields.push(input)
            }
          }
        }
      }
      return isValid
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.lang-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

#error-notice {
  width: 100%;
  color: rgb(214, 88, 88);
  text-align: left;
  font-size: 16px;
  padding-bottom: 20px;
  float: left;
  text-decoration: underline;
}

.debug-button {
  background-color: rgb(214, 88, 88);
  padding: 10px;
  margin: 20px 25%;
  cursor: pointer;
  border-radius: 5px;
}

.container {
  display: flex;
  justify-content: center;
  align-content: flex-start;
}

.submit-button {
  background-color: #64b281;
  color: #fff;
  padding: 10px 0;
  border-radius: 5px;
  margin: 30px 25% 120px 25%;
  cursor: pointer;
}

.submit-button-text {
  pointer-events: none;
}

h1 {
  color: #9cbaf2;
}

label {
  color: #fff;
}
</style>
